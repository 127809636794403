import React, { FC } from 'react'
import styles from './Logo.module.scss'

const Logo: FC = () => {
  const logoSrc: string = '/logo.png'

  if (logoSrc === '') return null

  return (
    <img
      src={logoSrc}
      className={styles.logo}
    />
  )
}

export default Logo
