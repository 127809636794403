import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Merriweather from "./assets/fonts/Merriweather-Regular.woff2";
import PTsans from "./assets/fonts/PTSans-Regular.woff2";
import { Font } from "@react-pdf/renderer";


Font.register({
  family: "Merriweather",
  src: Merriweather
});

Font.register({
  family: "PTsans",
  src: PTsans
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
