import React, { useState, useEffect } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { ethers } from 'ethers';
import axios from 'axios';
import { renderPaperCheckoutLink } from '@paperxyz/js-client-sdk';
import { useAccountModal, useConnectModal } from '@rainbow-me/rainbowkit';
import { PaperEmbeddedWalletSdk, UserStatus } from "@paperxyz/embedded-wallet-service-sdk";
import { PaperEmbeddedWalletProvider, ConnectButton } from '@paperxyz/embedded-wallet-service-rainbowkit';
import { darkTheme } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";
import FullHeader from './FullHeader/FullHeader';
import Container from './Layout/Container/Container';
import styles from './App.module.scss';
import Footer from './Footer/Footer';
import { Token } from './App';
import { getClaimConditionPrice } from './services/blockchainService';
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';



interface RouteParams {
  [key: string]: string | undefined;
  tokenId: string;
}

interface Props {
  tokens: Token[];
}

const TokenDetails: React.FC<Props> = ({ tokens }) => {
  const [nextTokens, setNextTokens] = useState<Token[]>([]);
  const [price, setPrice] = useState<string>('');
  const [checkoutLink, setCheckoutLink] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth <= 768; // or any other value you consider as a breakpoint
  const [imageClass, setImageClass] = useState('');
  const [priceUSD, setPriceUSD] = useState<number | null>(null);
  const multipliedPrice = priceUSD !== null ? priceUSD * parseFloat(price) : null;
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState<UserStatus | null>(null);
  const { openAccountModal } = useAccountModal();
  const { openConnectModal } = useConnectModal();
  const { isConnected, connector: activeConnector } = useAccount();
  const [size, setSize] = useState('');

  const { tokenId: tokenIdString } = useParams<RouteParams>();
  const tokenId = Number(tokenIdString);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWindowDimensions);

    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  // useEffect(() => {
  //   const fetchCurrentUser = async () => {
  //     const paperSdk = new PaperEmbeddedWalletSdk({
  //       clientId: process.env.REACT_APP_PAPER_CLIENT_ID!,
  //       chain: 'Ethereum',
  //       styles: {},
  //     });
  //     const user = await paperSdk.getUser();

  //     if (user.status === UserStatus.LOGGED_IN_WALLET_INITIALIZED) {
  //       setLoggedIn(true);

  //     } else {
  //       setLoggedIn(false);
  //     }
  //   };

  //   fetchCurrentUser();
  // }, []);

  useEffect(() => {
    if (loggedIn) {
      // Perform any desired action here
    } else {
      // Perform any desired action here
    }
  }, [loggedIn]);

  const handleClick = async () => {
    setIsLoading(true);
    const fetchedToken = tokens.find((token) => token.id === tokenId);
    try {
      const paperSdk = new PaperEmbeddedWalletSdk({
        clientId: process.env.REACT_APP_PAPER_CLIENT_ID!,
        chain: 'Ethereum',
        styles: {},
      });
      const userData = await paperSdk.getUser();
      if (
        userData.status === UserStatus.LOGGED_IN_WALLET_INITIALIZED &&
        userData.authDetails?.email &&
        userData.walletAddress &&
        fetchedToken
      ) {
        const imageUrl = `https://winweb-3-marketplace.vercel.app/PaperCheckoutImg/${tokenId}.webp`;
        const response = await fetch('/api/checkout-link', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            tokenId,
            email: userData.authDetails.email,
            walletAddress: userData.walletAddress,
            imageUrl,
            description: fetchedToken.description,
          }),
        });
        if (response.ok) {
          const responseBody = await response.json();
          if (
            responseBody &&
            responseBody.url &&
            typeof responseBody.url === 'string' &&
            responseBody.url.length > 0
          ) {
            setCheckoutLink(responseBody.url);
          } else {
            setCheckoutLink(null);
          }
        } else {
          throw new Error('Server response was not ok.');
        }
      } else {
        return <ConnectButton />;
      }
    } catch (error) {
      console.error('Failed to generate checkout link:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const handlePaymentSucceeded = ({ transactionId }: { transactionId: string }) => {
      console.log(`Payment succeeded. Transaction ID: ${transactionId}`);
    };

    const handlePaymentFailed = ({ transactionId }: { transactionId: string }) => {
      console.log(`Payment failed. Transaction ID: ${transactionId}`);
    };

    const handleTransferSucceeded = ({ transactionId, claimedTokens }: { transactionId: string; claimedTokens: Token[] }) => {
      console.log(`Transfer succeeded. Transaction ID: ${transactionId}, Claimed tokens: ${claimedTokens}`);
    };

    const handleModalClosed = () => {
      console.log('Modal closed');
    };

    if (checkoutLink) {
      renderPaperCheckoutLink({
        checkoutLinkUrl: checkoutLink,
        onPaymentSucceeded: handlePaymentSucceeded,
        onPaymentFailed: handlePaymentFailed,
        onTransferSucceeded: handleTransferSucceeded,
        onModalClosed: handleModalClosed,
      });
    }
  }, [checkoutLink]);

  const handleConnect = () => {
    if (openConnectModal) {
      openConnectModal();
    }
  };

  const handleDisconnect = () => {
    if (openAccountModal) {
      openAccountModal();
    }
  };

  const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    // Handle image load logic
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tokenId]);

  useEffect(() => {
    const fetchPrice = async () => {
      const tokenPrice = await getClaimConditionPrice(tokenId);
      setPrice(ethers.utils.formatEther(tokenPrice));
    };
    fetchPrice();
  }, [tokenId]);

  useEffect(() => {
    const fetchPriceUSD = async () => {
      try {
        const response = await axios.get(
          `https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd`
        );
        if (response.data && response.data.ethereum && response.data.ethereum.usd) {
          const priceUSD = response.data.ethereum.usd;
          setPriceUSD(priceUSD);
        }
      } catch (error) {
        console.error('Failed to fetch price in USD:', error);
      }
    };
    fetchPriceUSD();
  }, [tokenId]);

  useEffect(() => {
    const randomIds = generateRandomNumbers();
    const fetchedTokens = randomIds
      .map((id) => tokens.find((token) => token.id === id))
      .filter((token) => token !== undefined) as Token[];
    setNextTokens(fetchedTokens);
  }, [tokenId, isMobile]);

  function generateRandomNumbers() {
    let amountToDisplay;
    if (windowWidth > 1120) {
      amountToDisplay = 3;
    } else if (windowWidth <= 1245 && windowWidth > 769) {
      amountToDisplay = 2;
    } else {
      amountToDisplay = 1;
    }
    let randomNumbers: number[] = [];
    while (randomNumbers.length < amountToDisplay) {
      let r = Math.floor(Math.random() * 12) + 1;
      if (randomNumbers.indexOf(r) === -1 && r !== tokenId) randomNumbers.push(r);
    }
    return randomNumbers;
  }

  const fetchedToken = tokens.find((token) => token.id === tokenId);
  if (!fetchedToken) return <div>Token not found</div>;
  const { name, artist, description, country, physical, editionSize } = fetchedToken;
  const physicalDisplay = physical === 'Digital Plus Physical' ? 'Digital (Physical Available)' : 'Digital (Physical: N/A)';
  
  
  

  const imageUrl = `https://winweb-3-marketplace.vercel.app/PaperCheckoutImg/${tokenId}.webp`;

  const renderButton = () => {
    if (loggedIn) {
      return <button className={styles.CardButtonThree} onClick={handleClick}>Purchase</button>;
    } else {
      return (
        <PaperEmbeddedWalletProvider
          appName="WinWeb3"
          walletOptions={{
            clientId: process.env.REACT_APP_PAPER_CLIENT_ID!,
            chain: 'Ethereum',
            iconUrl: 'https://withpaper.com/icons/paper-embedded-wallet-white.png',
            iconBackground: '#432918',
          }}
          modalOptions={{
            theme: darkTheme({
              accentColor: '#A48A7B',
              accentColorForeground: 'white',
              borderRadius: 'small',
              fontStack: 'system',
              overlayBlur: 'small',
            }),
          }}
          otherWallets={[]}
        >
          <ConnectButton>
            <button className={styles.CardButtonThree} onClick={handleClick}>Purchase</button>
          </ConnectButton>
        </PaperEmbeddedWalletProvider>
      );
    }
  };

  const getImageSrc = (url: string, size: string): string => {
    const screenSize = window.innerWidth;
  
    if (screenSize >= 1920) {
      size = "Desktop";
    } else if (screenSize >= 1366) {
      size = "public";
    } else if (screenSize >= 768) {
      size = "Tablet";
    } else {
      size = "Mobile";
    }
  
    return `https://imagedelivery.net/KUkN6Roy9yZq57ikdpH6wg/${url}/${size}`;
  };
  

  const imageUrls = [
    "2a5756b9-f0b7-4914-2dfe-2eafc2765400",
    "5f530b65-73a0-4475-c987-ea5607a41d00",
    "4e7490d0-3656-4b9b-1805-1948cf456c00",
    "0b171244-fd2b-4ec8-c6f7-3ae827847b00",
    "737fe88d-7a3a-4f69-addc-864a23d81200",
    "0284ef39-6a70-47ba-080d-4617a8ec0f00",
    "a7daff82-f6a7-4263-0fbb-c519a0cefc00",
    "ca9606a2-fd16-4aba-ac1a-813d9d1f6000",
    "39986f12-4355-40f3-fdd9-7be8633f0b00",
    "14d4a776-5dce-4c7d-421e-0b108f39cc00",
    "89841cbe-c2bf-4f0b-f592-0c957eeb5500",
    "0ffd92c4-9eec-4a0c-883c-c49c8ad24300",
    "5ca6c10f-9273-43b6-6150-25e9ea618400",
    "50731782-0452-4622-8935-140377d99d00",
    "ab42a51e-a4e3-45d3-9e2d-3938da5cec00",
    "67ef0f0c-9d6d-4ac8-26c5-54c534096700",
    "42205d37-c8d6-481c-d798-7302e52b8900",
    "bd6e0064-bfed-4e1a-759d-763fd4cf3c00",
    "a00bce6f-780d-498d-f29f-076a63ec0200",
    "4634ae52-cd55-477a-f8af-1d10aae1c100",
    "c396f396-4dd2-454b-fa6b-fe636e74ec00",
    "0b6d0fb8-c3a2-4cae-cf9b-23c86b612f00",
    "6ed91af9-8fd0-43b9-5680-579d44713a00",
    "35c63407-0694-4f8a-a7cf-9566466f6b00",
    "3b20b439-c811-4b82-dc57-34d71a0c0e00",
    "a57982ae-1f50-49ca-7097-59687b2cf800",
    "531a17a5-1a91-4399-d79e-fabd18d10b00",
    "b35005d6-e91a-419b-7368-ba9a968f9700",
    "35fcd07f-8943-4279-9fa5-58c536e81f00",
    "2ecf2505-183b-4c44-45ce-6b3e4198ff00",
    "09726138-9b77-40ef-02a4-c16768a06600",
    "d5138761-8a06-4cdc-1cd7-bce1d3644d00",
    "710bbc0c-4f5d-4173-6cbc-823e6d676f00",
    "22162a5d-76b2-4ee3-e4b1-36f2006f1200",
    "a1cab993-a819-4f0c-0682-ff90d5877500",
    "b7d3bab9-f893-44c5-53bc-41d2bd80a800",
    "7aeb8306-4d5d-46ce-bc02-0c5b2a8cbd00",
    "f784c001-7c33-4572-af53-3c68c373cd00",
    "89310e32-53c3-460c-a98e-f4a33074e400",
    "20f1b720-440a-4ee0-9982-982299d94300",
    "c004e07c-60f7-4939-d45d-5dc58ac4e100",
    "6ff93ed8-051f-48c1-5a47-588493aadf00",
    "8f3de34c-f05e-440e-0029-2fa762f4de00",
    "bed4a69e-52ff-426a-8acb-0a666f6c5800",
    "c0f25290-2aee-4514-cf7d-4692ddebd200",
    "d2c2b1be-c7ac-43a3-f252-edaf9846c800",
    "5c1e0007-4e40-4b68-ce15-7e7d19601600",
    "b93e771e-29a8-4224-554d-c18da77f0800",
    "1f583ef4-5ace-4d79-2386-e664ff10e100",
    "a70710ba-5d4e-4f0a-ce2b-1b7bb3ce5d00",
    "8b3c2997-1518-4d59-02e1-98917aa82500",
  ];

  return (
    <>
      <FullHeader />
      <div className={styles.tokenPage}>
        <Container>
          <div className={styles.fullContainer}>
            <div className={styles.leftSide}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <NavLink
                  to="/"
                  style={{
                    font: 'PT Sans',
                    fontSize: '1.5rem',
                    color: '#9D8375',
                    textDecoration: 'underline',
                    padding: '10px',
                    borderRadius: '5px',
                    marginBottom: '10px',
                  }}
                >
                  Back to the marketplace
                </NavLink>
                <img className={`${styles.artImage} ${imageClass}`} src={getImageSrc(imageUrls[tokenId], window.innerWidth.toString())} alt="Token" onLoad={handleImageLoad} />
              </div>
            </div>
            <div className={styles.rightSide}>
              <div className={styles.titleToken}>{name}</div>
              <div className={styles.descriptionWrap}>
                {description && (
                  <>
                    <div className={styles.LabelDescription}>Description</div>
                    <div className={styles.description}>{description}</div>
                  </>
                )}
              </div>
              <div className={styles.Label}>Artist</div>
              <div className={styles.artistName}>{artist}</div>
              <div className={styles.Label}>Country</div>
              <div className={styles.artistName}>{country}</div>
              <div className={styles.Label}>Art Type</div>
              <div className={styles.artistName}>{physicalDisplay || 'N/A'}</div>
              <div className={styles.Label}>Sale Type</div>
              <div className={styles.artistName}>{editionSize}</div>
              <div className={styles.Label}>Price</div>
              <div className={styles.totalWrap}>
                <div className={styles.Label}></div>
                {multipliedPrice !== null ? (
                  <div className={styles.totalNumbers}>${multipliedPrice.toFixed(2)}</div>
                ) : (
                  <div>Loading...</div>
                )}
              </div>
              <div>
                {renderButton()}
              </div>
            </div>
          </div>
        </Container>
        <Container className={styles.hero} width="narrow" />
        <Container className={styles.main} width="narrow">
          <h1 className={styles.bottomTokenHeadline}>Also Viewed</h1>
          <section className={styles.gridTokenPage}>
  {nextTokens.map((nft) => {
    const imageUrl = getImageSrc(imageUrls[nft.tokenId], ''); // Subtract 1 if token IDs are zero-based
    return (
      <article className={styles.box2} key={nft.tokenId}>
      <NavLink
        style={{ textDecoration: 'none', color: '#432918' }}
        to={`/token/${nft.tokenId}`}
      >
        <div className={styles.topCard}>
          <div className={styles.imageWrap}>
            <LazyLoadImage
              className={styles.imageCard}
              src={imageUrl}
              alt="Token"
              threshold={150}
            />
          </div>
        </div>
        <div className={styles.bottomCard}>
          <header className={styles.titleCard}>
            <NavLink
              style={{ textDecoration: 'none', color: '#432918' }}
              to={`/token/${nft.tokenId}`}
            >
              {nft.name}
            </NavLink>
          </header>
          <div className={styles.artistCard}>
            <span>{nft.artist}</span>
            <div className={styles.countryCard}>
              <span>{nft.country}</span>
              <span className={styles.saleType}>{nft.editionSize}</span>
            </div>
          </div>
        </div>
      </NavLink>
    </article>
  );
})}

          </section>
          <div className={styles.centerContainer}>
            <div className={styles.backHomelink}>
              <NavLink
                to="/"
                style={{
                  font: 'PT Sans',
                  fontSize: '1.5rem',
                  color: '#9D8375',
                  textDecoration: 'underline',
                  padding: '10px',
                  borderRadius: '5px',
                  marginBottom: '10px',
                }}
              >
                Back to the marketplace
              </NavLink>
            </div>
          </div>
        </Container>
      </div>
      <LazyLoadComponent threshold={150}>
      <Footer />
      </LazyLoadComponent>
    </>
  );
};

export default TokenDetails;
