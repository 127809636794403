import React, { useState, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Dropdown, Button, Icon, Modal, Grid } from 'semantic-ui-react';
import { PaperEmbeddedWalletSdk, UserStatus } from "@paperxyz/embedded-wallet-service-sdk";
import { PaperEmbeddedWalletProvider } from '@paperxyz/embedded-wallet-service-rainbowkit';
import { darkTheme } from "@rainbow-me/rainbowkit";
import { BackToTop } from '@patternfly/react-core';
import Container from './Layout/Container';
import FullHeader from './FullHeader/FullHeader';
import HeaderCard from './HeaderCard/HeaderCard';
import TokenDetail from './TokenDetails';
import Footer from './Footer/Footer';
import titleContent from './titleContent.json';
import './semantic-ui-css/semantic.min.css';
import './scss/index.scss';
import { getClaimConditionPrice } from './services/blockchainService';
import { ethers } from 'ethers';
import styles from './App.module.scss';
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';

export interface Trait {
  trait_type: string;
  value: string;
}

export interface Token {
  id: number;
  tokenId: number;
  name: string;
  image: string;
  description: string;
  artist: string;
  country: string;
  sale: string;
  traits: Trait[];
  saleType: string;
  physicalOption: string;
  artistName: string;
  artForm: string;
  physical: string;
  type: string;
  price: number;
  UsdPrice: number;
  priceUSD: number;
  editionSize: string;
}

const App: React.FC = () => {
  const [email, setEmail] = useState<string>('user@example.com');
  const [walletAddress, setWalletAddress] = useState<string>('userWalletAddress');
  const [prices, setPrices] = useState<{ [tokenId: number]: string }>({});
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPhysical, setSelectedPhysical] = useState<string | null>(null);
  const [selectedArtist, setSelectedArtist] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedTypeOfArt, setSelectedTypeOfArt] = useState('');
  const [openArtistDropdown, setArtistDropdownOpen] = useState(false);
  const [openCountryDropdown, setCountryDropdownOpen] = useState(false);
  const [openTypeOfArtDropdown, setTypeOfArtDropdownOpen] = useState(false);
  const [openPhysicalDropdown, setPhysicalDropdownOpen] = useState(false);
  const [tokens, setTokens] = useState<Token[]>([]);
  const [filteredTokens, setFilteredTokens] = useState<Token[]>([]);
  const uniqueArtists = useMemo(() => Array.from(new Set(tokens.map(token => token.artist))), [tokens]);
  const uniqueCountries = useMemo(() => Array.from(new Set(tokens.map(token => token.country))), [tokens]);
  const [sortingOrder, setSortingOrder] = useState('');
  const [editionFilter, setEditionFilter] = useState<string | undefined>(undefined);
  const [ethUsdPrice, setEthUsdPrice] = useState(1);
  const [open, setOpen] = useState(false);

  const clientId = process.env.REACT_APP_PAPER_CLIENT_ID || "";

  const showFilterModule = () => {
    setOpen(true);
  };

  const closeFilterModule = () => {
    setOpen(false);
  };
  

  useEffect(() => {
    const fetchPrice = async (tokenId: number) => {
      const tokenPrice = await getClaimConditionPrice(tokenId);
      const tokenPriceInUSD = parseFloat(ethers.utils.formatEther(tokenPrice)) * ethUsdPrice;
      setPrices((prevPrices) => ({ ...prevPrices, [tokenId]: tokenPriceInUSD.toFixed(2) }));
    };

    // Fetch price for each token in the tokens array
    tokens.forEach((token) => {
      fetchPrice(token.tokenId);
    });
  }, [tokens, ethUsdPrice]);

  // Get unique "Type of Art" and "Physical" traits
  const uniqueTypeOfArts = useMemo(
    () =>
      Array.from(
        new Set(
          tokens.flatMap((token) =>
            token.traits
              .filter((trait: Trait) => trait.trait_type === "Type of art")
              .map((trait) => trait.value)
          )
        )
      ),
    [tokens]
  );
  const uniquePhysicals = useMemo(
    () =>
      Array.from(
        new Set(
          tokens.flatMap((token) =>
            token.traits
              .filter((trait: Trait) => trait.trait_type === "Physical")
              .map((trait) => trait.value)
          )
        )
      ),
    [tokens]
  );

  // Filter tokens based on the selected filters
  useEffect(() => {
    let newFilteredTokens = [...tokens];

    if (selectedArtist) {
      newFilteredTokens = newFilteredTokens.filter((token) => token.artist === selectedArtist);
    }

    if (selectedCountry) {
      newFilteredTokens = newFilteredTokens.filter((token) => token.country === selectedCountry);
    }

    if (selectedTypeOfArt) {
      newFilteredTokens = newFilteredTokens.filter((token) =>
        token.traits.find((trait: Trait) => trait.trait_type === "Type of art" && trait.value === selectedTypeOfArt)
      );
    }

    if (selectedPhysical) {
      newFilteredTokens = newFilteredTokens.filter((token) =>
        token.traits.find((trait: Trait) => trait.trait_type === "Physical" && trait.value === selectedPhysical)
      );
    }

    if (sortingOrder === "highToLow") {
      newFilteredTokens.sort((a, b) => Number(prices[b.tokenId]) - Number(prices[a.tokenId]));
    } else if (sortingOrder === "lowToHigh") {
      newFilteredTokens.sort((a, b) => Number(prices[a.tokenId]) - Number(prices[b.tokenId]));
    }
    if (editionFilter === "1/1") {
      newFilteredTokens = newFilteredTokens.filter((token) => {
        const editionTrait = token.traits.find(
          (trait) => trait.trait_type === "Edition Size" && trait.value === "1/1"
        );
        return editionTrait !== undefined;
      });
    } else if (editionFilter === "Open edition") {
      newFilteredTokens = newFilteredTokens.filter((token) => {
        const editionTrait = token.traits.find(
          (trait) => trait.trait_type === "Edition Size" && trait.value === "Open Edition"
        );
        return editionTrait !== undefined;
      });
    }
    setFilteredTokens(newFilteredTokens);
  }, [
    tokens,
    selectedArtist,
    selectedCountry,
    selectedTypeOfArt,
    selectedPhysical,
    sortingOrder,
    editionFilter,
  ]);

  const data: { title: string }[] = titleContent;
  const results = data.filter((d) => d.title.toLowerCase().includes(searchQuery.toLowerCase()));

  // useEffect(() => {
  //   const fetchCurrentUser = async () => {
  //     const paperSdk = new PaperEmbeddedWalletSdk({
  //       clientId: process.env.REACT_APP_PAPER_CLIENT_ID!,
  //       chain: "Ethereum",
  //       styles: {},
  //     });

  //     const user = await paperSdk.getUser();

  //     if (user.status === UserStatus.LOGGED_IN_WALLET_INITIALIZED) {
  //       setEmail(user.authDetails?.email || '');
  //       setWalletAddress(user.walletAddress || '');
  //     } else {
  //     }
  //   };

  //   fetchCurrentUser();
  // }, []);

  useEffect(() => {
    // Fetch current ETH to USD price
    const fetchEthUsdPrice = async () => {
      try {
        const response = await fetch("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD");
        const data = await response.json();
        setEthUsdPrice(data.USD);
      } catch (error) {
        console.error('Failed to fetch ETH to USD price:', error);
      }
    };

    fetchEthUsdPrice();
  }, []);

  useEffect(() => {
    const fetchTokens = async () => {
      let fetchPromises = [];
  
      for (let i = 0; i <= 50; i++) {
        const fetchPromise = fetch(`https://bafybeigg4nma75o6eojccbgexigyzerc44wtyqkr3q4nbw65464b2ja7ha.ipfs.4everland.io/${i}`)
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error(`Response not ok for token ${i}`);
            }
          })
          .then((metadata) => {
            metadata.image = `/tokenImages/${i}.jpg`;
  
            const traits: Trait[] = [];
  
            metadata.attributes.forEach((attribute: { trait_type: string; value: string }) => {
              traits.push({
                trait_type: attribute.trait_type,
                value: attribute.value,
              });
            });
  
            // Set the "Digital Plus Physical" option for tokens 49 and 50
            if (i === 49 || i === 50) {
              traits.push({
                trait_type: "Physical",
                value: "Digital Plus Physical",
              });
            }
  
            return {
              id: i,
              tokenId: i,
              name: metadata.name,
              image: metadata.image,
              description: metadata.description,
              artist: traits.find((trait) => trait.trait_type.toLowerCase() === "artist")?.value || "",
              country: traits.find((trait) => trait.trait_type.toLowerCase() === "country")?.value || "",
              editionSize: traits.find((trait) => trait.trait_type.toLowerCase() === "edition size")?.value || "",
              traits,
              saleType: traits.find((trait) => trait.trait_type.toLowerCase() === "sale type")?.value || "",
              physicalOption: traits.find((trait) => trait.trait_type.toLowerCase() === "physical option")?.value || "",
              artistName: traits.find((trait) => trait.trait_type.toLowerCase() === "artist name")?.value || "",
              artForm: traits.find((trait) => trait.trait_type.toLowerCase() === "art form")?.value || "",
              physical: traits.find((trait) => trait.trait_type.toLowerCase() === "physical")?.value || "",
            };
          })
          .catch((err) => {
            console.log(`Error fetching token ${i}:`, err);
            return null;
          });
  
        fetchPromises.push(fetchPromise);
      }
  
      const tokens = (await Promise.all(fetchPromises)).filter((token) => token !== null) as Token[];
      setTokens(tokens);
    };
  
    fetchTokens();
  }, []);


  const imageUrls = [
    "2a5756b9-f0b7-4914-2dfe-2eafc2765400",
    "5f530b65-73a0-4475-c987-ea5607a41d00",
    "4e7490d0-3656-4b9b-1805-1948cf456c00",
    "0b171244-fd2b-4ec8-c6f7-3ae827847b00",
    "737fe88d-7a3a-4f69-addc-864a23d81200",
    "0284ef39-6a70-47ba-080d-4617a8ec0f00",
    "a7daff82-f6a7-4263-0fbb-c519a0cefc00",
    "ca9606a2-fd16-4aba-ac1a-813d9d1f6000",
    "39986f12-4355-40f3-fdd9-7be8633f0b00",
    "14d4a776-5dce-4c7d-421e-0b108f39cc00",
    "89841cbe-c2bf-4f0b-f592-0c957eeb5500",
    "0ffd92c4-9eec-4a0c-883c-c49c8ad24300",
    "5ca6c10f-9273-43b6-6150-25e9ea618400",
    "50731782-0452-4622-8935-140377d99d00",
    "ab42a51e-a4e3-45d3-9e2d-3938da5cec00",
    "67ef0f0c-9d6d-4ac8-26c5-54c534096700",
    "42205d37-c8d6-481c-d798-7302e52b8900",
    "bd6e0064-bfed-4e1a-759d-763fd4cf3c00",
    "a00bce6f-780d-498d-f29f-076a63ec0200",
    "4634ae52-cd55-477a-f8af-1d10aae1c100",
    "c396f396-4dd2-454b-fa6b-fe636e74ec00",
    "0b6d0fb8-c3a2-4cae-cf9b-23c86b612f00",
    "6ed91af9-8fd0-43b9-5680-579d44713a00",
    "35c63407-0694-4f8a-a7cf-9566466f6b00",
    "3b20b439-c811-4b82-dc57-34d71a0c0e00",
    "a57982ae-1f50-49ca-7097-59687b2cf800",
    "531a17a5-1a91-4399-d79e-fabd18d10b00",
    "b35005d6-e91a-419b-7368-ba9a968f9700",
    "35fcd07f-8943-4279-9fa5-58c536e81f00",
    "2ecf2505-183b-4c44-45ce-6b3e4198ff00",
    "09726138-9b77-40ef-02a4-c16768a06600",
    "d5138761-8a06-4cdc-1cd7-bce1d3644d00",
    "710bbc0c-4f5d-4173-6cbc-823e6d676f00",
    "22162a5d-76b2-4ee3-e4b1-36f2006f1200",
    "a1cab993-a819-4f0c-0682-ff90d5877500",
    "b7d3bab9-f893-44c5-53bc-41d2bd80a800",
    "7aeb8306-4d5d-46ce-bc02-0c5b2a8cbd00",
    "f784c001-7c33-4572-af53-3c68c373cd00",
    "89310e32-53c3-460c-a98e-f4a33074e400",
    "20f1b720-440a-4ee0-9982-982299d94300",
    "c004e07c-60f7-4939-d45d-5dc58ac4e100",
    "6ff93ed8-051f-48c1-5a47-588493aadf00",
    "8f3de34c-f05e-440e-0029-2fa762f4de00",
    "bed4a69e-52ff-426a-8acb-0a666f6c5800",
    "c0f25290-2aee-4514-cf7d-4692ddebd200",
    "d2c2b1be-c7ac-43a3-f252-edaf9846c800",
    "5c1e0007-4e40-4b68-ce15-7e7d19601600",
    "b93e771e-29a8-4224-554d-c18da77f0800",
    "1f583ef4-5ace-4d79-2386-e664ff10e100",
    "a70710ba-5d4e-4f0a-ce2b-1b7bb3ce5d00",
    "8b3c2997-1518-4d59-02e1-98917aa82500",
  ];

  const getImageSrc = (url: string): string => {
    const screenSize = window.innerWidth;

    let size;
    if (screenSize >= 1920) {
      size = "Desktop";
    } else if (screenSize >= 1366) {
      size = "public";
    } else if (screenSize >= 768) {
      size = "Tablet";
    } else {
      size = "Mobile";
    }

    return `https://imagedelivery.net/KUkN6Roy9yZq57ikdpH6wg/${url}/${size}`;
  };

  
  const TokenList = () => {
    // Rest of the TokenList component code
  };

  const tokenGrid = (
    <div className={styles.tokenGrid}>
      <div className={styles.grid}>
        {/* Map over filteredTokens to display the tokens */}
        {filteredTokens.map((token, index) => (
          <article className={styles.box} key={index}>
            <Link style={{ textDecoration: "none", color: "#000" }} to={`/token/${token.tokenId}`}>
              <div className={styles.topCard}>
                <div key={index} className={styles.imageWrap}>
                  {/* Use the getImageSrc function to retrieve the image URL from imageUrls */}
                  <LazyLoadImage className={styles.imageCard} src={getImageSrc(imageUrls[token.tokenId])} alt="Token" threshold={150}  // this means the image will start loading 150px before it appears on screen 
                  />
                </div>
              </div>
              <div className={styles.bottomCard}>
                <header className={styles.titleCard}>
                  <Link
                    style={{ textDecoration: "none", color: "rgb(67,41,24)" }}
                    to={`/token/${token.tokenId}`}
                  >
                    {token.name}
                  </Link>
                </header>
                <div className={styles.artistAndPriceCard}>
                  <div className={styles.artistCard}>
                    <span>{token.artist}</span>
                  </div>
                  {prices[token.tokenId] && (
                    <div className={styles.priceCard}>
                      <span className={styles.price}>${prices[token.tokenId]}</span>
                    </div>
                  )}
                </div>
                <div className={styles.countryCard}>
                  <span>{token.country}</span>
                  <span className={styles.saleType}>{token.editionSize}</span>
                </div>
              </div>
            </Link>
          </article>
        ))}
      </div>
    </div>
  );

return (
  <>
     <div className={styles.app}>
        <PaperEmbeddedWalletProvider
          appName="WinWeb3"
          walletOptions={{
            clientId: process.env.REACT_APP_PAPER_CLIENT_ID!,
            chain: "Ethereum",
            iconUrl: "https://withpaper.com/icons/paper-embedded-wallet-white.png",
            iconBackground: "#432918",
          }}
          modalOptions={{
            theme: darkTheme({
              accentColor: "#A48A7B",
              accentColorForeground: "white",
              borderRadius: "small",
              fontStack: "system",
              overlayBlur: "small",
            }),
          }}
          otherWallets={[]}
        >
          <Router>
            <Routes>
              <Route path="/token/:tokenId" element={<TokenDetail tokens={tokens} />} />
              <Route
                path="/"
                element={
                  <>
                    <FullHeader />
                    <HeaderCard />
                    <div className={styles.filterButtonContainer}>
                      <Button className={styles.filterButton} onClick={showFilterModule}>
                        Filter
                      </Button>
                    </div>
                    <Container className={styles.hero} width="wide" />
                    <Container className={styles.main} width="wide">
                      <div className={styles.gridContainer}>
                        <div className={`${styles.sidebar} ${styles.desktopSidebar}`}>
                          <div className={styles.sidebarContent}>
                            <div className={styles.filterSearchContainer}>
                            <div className={styles.filterDropdown}>
                              <Dropdown
                                className={styles.filterDropdown}
                                searchable
                                selection
                                clearable
                                placeholder="Sort by Price"
                                options={[
                                  { key: "placeholder", text: "Sort by Price", value: "" },
                                  { key: "highToLow", text: "High to Low", value: "highToLow" },
                                  { key: "lowToHigh", text: "Low to High", value: "lowToHigh" },
                                ]}
                                value={sortingOrder || ""}
                                onChange={(_e, { value }) => setSortingOrder(value as string)}
                              />
                              <Dropdown
                                className={styles.filterDropdown}
                                selection
                                clearable
                                options={[
                                  { key: "all", text: "All Editions", value: "" },
                                  { key: "1/1", text: "1/1", value: "1/1" },
                                  { key: "open", text: "Open Edition", value: "Open edition" },
                                ]}
                                placeholder="Filter by Edition Size"
                                value={editionFilter}
                                onChange={(_e, { value }) => setEditionFilter(value as string | undefined)}
                              />
                              <Dropdown
                                className={styles.filterDropdown}
                                selection
                                clearable
                                placeholder="Filter by Art"
                                open={openTypeOfArtDropdown}
                                onOpen={() => setTypeOfArtDropdownOpen(true)}
                                onClose={() => setTypeOfArtDropdownOpen(false)}
                                options={[
                                  { key: "all_types_of_art", text: "All Types of Art", value: "" },
                                  ...uniqueTypeOfArts
                                    .filter((typeOfArt) => typeOfArt)
                                    .map((typeOfArt, index) => ({ key: index, text: typeOfArt, value: typeOfArt })),
                                ]}
                                value={selectedTypeOfArt || ""}
                                onChange={(_e, data) => {
                                  setTypeOfArtDropdownOpen(false);
                                  setSelectedTypeOfArt(data.value as string);
                                }}
                              />
                              <Dropdown
                                className={styles.filterDropdown}
                                selection
                                clearable
                                placeholder="Filter by Physical"
                                open={openPhysicalDropdown}
                                onOpen={() => setPhysicalDropdownOpen(true)}
                                onClose={() => setPhysicalDropdownOpen(false)}
                                options={[
                                  { key: "all_physical", text: "All Pieces", value: "" },
                                  ...uniquePhysicals
                                    .filter((physical) => physical)
                                    .map((physical, index) => ({ key: index, text: physical, value: physical })),
                                ]}
                                value={selectedPhysical || ""}
                                onChange={(_e, data) => {
                                  setPhysicalDropdownOpen(false);
                                  setSelectedPhysical(data.value as string);
                                }}
                              />
                              {/* Artist dropdown */}
                              <Dropdown
                                className={styles.filterDropdown}
                                clearable
                                selection
                                options={[
                                  { key: "all_artists", text: "All Artists", value: "" },
                                  ...uniqueArtists.map((artist) => ({ key: artist, value: artist, text: artist })),
                                ]}
                                placeholder="Filter by Artist"
                                onChange={(_e, { value }) => setSelectedArtist(value as string)}
                                onOpen={() => setArtistDropdownOpen(true)}
                                onClose={() => setArtistDropdownOpen(false)}
                                open={openArtistDropdown}
                              />
                              {/* Country dropdown */}
                              <Dropdown
                                className={styles.filterDropdownLast}
                                clearable
                                selection
                                options={[
                                  { key: "all_countries", text: "All Countries", value: "" },
                                  ...uniqueCountries.map((country) => ({ key: country, value: country, text: country })),
                                ]}
                                placeholder="Filter by Country"
                                onChange={(_e, { value }) => setSelectedCountry(value as string)}
                                onOpen={() => setCountryDropdownOpen(true)}
                                onClose={() => setCountryDropdownOpen(false)}
                                open={openCountryDropdown}
                              />
                            </div>
                          </div>
                          <div className={styles.backToTop}>
                              <BackToTop className={styles.backToTop} />
                            </div>
                          </div>
                        </div>
                        <div className={styles.tokenGridContainer}>{tokenGrid}</div>
                      </div>
                    </Container>
                    <LazyLoadComponent>
                    <Footer />
                    </LazyLoadComponent>

                  </>
                }
              />
            </Routes>
          </Router>
        </PaperEmbeddedWalletProvider>
      </div>
      <div className={styles.filterModule}>
      <Modal basic onClose={closeFilterModule} onOpen={showFilterModule} open={open} size="small">
        <Modal.Header>
          <Icon name="filter" /> Filter Options
        </Modal.Header>
        <Modal.Content>
          <Grid centered>
            <Grid.Row>
              <Grid.Column>
                <Dropdown
                  className={styles.filterDropdown}
                  searchable
                  selection
                  clearable
                  placeholder="Sort by Price"
                  options={[
                    { key: "placeholder", text: "Sort by Price", value: "" },
                    { key: "highToLow", text: "High to Low", value: "highToLow" },
                    { key: "lowToHigh", text: "Low to High", value: "lowToHigh" },
                  ]}
                  value={sortingOrder || ""}
                  onChange={(_e, { value }) => setSortingOrder(value as string)}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Dropdown
                  className={styles.filterDropdown}
                  selection
                  clearable
                  options={[
                    { key: "all", text: "All Editions", value: "" },
                    { key: "1/1", text: "1/1", value: "1/1" },
                    { key: "open", text: "Open Edition", value: "Open edition" },
                  ]}
                  placeholder="Filter by Edition Size"
                  value={editionFilter}
                  onChange={(_e, { value }) => setEditionFilter(value as string | undefined)}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Dropdown
                  className={styles.filterDropdown}
                  selection
                  clearable
                  placeholder="Filter by Art"
                  open={openTypeOfArtDropdown}
                  onOpen={() => setTypeOfArtDropdownOpen(true)}
                  onClose={() => setTypeOfArtDropdownOpen(false)}
                  options={[
                    { key: "all_types_of_art", text: "All Types of Art", value: "" },
                    ...uniqueTypeOfArts
                      .filter((typeOfArt) => typeOfArt)
                      .map((typeOfArt, index) => ({ key: index, text: typeOfArt, value: typeOfArt })),
                  ]}
                  value={selectedTypeOfArt || ""}
                  onChange={(_e, data) => {
                    setTypeOfArtDropdownOpen(false);
                    setSelectedTypeOfArt(data.value as string);
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Dropdown
                  className={styles.filterDropdown}
                  selection
                  clearable
                  placeholder="Filter by Physical"
                  open={openPhysicalDropdown}
                  onOpen={() => setPhysicalDropdownOpen(true)}
                  onClose={() => setPhysicalDropdownOpen(false)}
                  options={[
                    { key: "all_physical", text: "All Pieces", value: "" },
                    ...uniquePhysicals
                      .filter((physical) => physical)
                      .map((physical, index) => ({ key: index, text: physical, value: physical })),
                  ]}
                  value={selectedPhysical || ""}
                  onChange={(_e, data) => {
                    setPhysicalDropdownOpen(false);
                    setSelectedPhysical(data.value as string);
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {/* Artist dropdown */}
                <Dropdown
                  className={styles.filterDropdown}
                  clearable
                  selection
                  options={[
                    { key: "all_artists", text: "All Artists", value: "" },
                    ...uniqueArtists.map((artist) => ({ key: artist, value: artist, text: artist })),
                  ]}
                  placeholder="Filter by Artist"
                  onChange={(_e, { value }) => setSelectedArtist(value as string)}
                  onOpen={() => setArtistDropdownOpen(true)}
                  onClose={() => setArtistDropdownOpen(false)}
                  open={openArtistDropdown}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {/* Country dropdown */}
                <Dropdown
                  className={styles.filterDropdownLast}
                  clearable
                  selection
                  options={[
                    { key: "all_countries", text: "All Countries", value: "" },
                    ...uniqueCountries.map((country) => ({ key: country, value: country, text: country })),
                  ]}
                  placeholder="Filter by Country"
                  onChange={(_e, { value }) => setSelectedCountry(value as string)}
                  onOpen={() => setCountryDropdownOpen(true)}
                  onClose={() => setCountryDropdownOpen(false)}
                  open={openCountryDropdown}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <div className={styles.mobileFilterButton}>
            <Button className={styles.mobileFilterButton} inverted onClick={closeFilterModule}>
              <Icon name="checkmark" /> Apply
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    </div>
  </>
);
}

export default App;