import React, { FC, useState, useEffect, useRef } from 'react';
import Logo from '../Common/Logo/Logo';
import Container from '../Layout/Container/Container';
import styles from "./Header.module.scss";
import { PaperEmbeddedWalletProvider } from '@paperxyz/embedded-wallet-service-rainbowkit';
import { darkTheme } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { PaperEmbeddedWalletSdk, UserStatus, InitializedUser } from "@paperxyz/embedded-wallet-service-sdk";
import { useAccountModal, useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccount } from "wagmi";
import HeaderMenu from '../HeaderMenu/HeaderMenu';

const clientId = process.env.REACT_APP_PAPER_CLIENT_ID || "";

const Header: FC = () => {
  const [user, setUser] = useState<InitializedUser | null>(null);
  const { openAccountModal } = useAccountModal();
  const { openConnectModal } = useConnectModal();
  const { isConnected } = useAccount();
  const buttonRef = useRef(null);

  const paperSdk = useRef<PaperEmbeddedWalletSdk | null>(null);

  const fetchCurrentUser = async () => {
    const currentUser = await paperSdk.current?.getUser();
    console.log("User data fetched:", currentUser);

    if (currentUser?.status === UserStatus.LOGGED_IN_WALLET_INITIALIZED) {
      setUser(currentUser);
      console.log("User set:", currentUser.authDetails?.email, currentUser.walletAddress);
    } else {
      console.log("User not logged in or wallet not initialized");
      setUser(null);
    }
  };

  useEffect(() => {
    const initializeSdk = async () => {
      const sdk = new PaperEmbeddedWalletSdk({
        clientId: process.env.REACT_APP_PAPER_CLIENT_ID || "",
        chain: "Goerli",
        styles: {},
      });
      paperSdk.current = sdk;
  
      await fetchCurrentUser();
    };
  
    initializeSdk();
  }, []);
  

  const handleDisconnect = async () => {
    if (openAccountModal) {
      openAccountModal();
    }

    // Ideally you should call a disconnect method from the SDK here and wait for it to finish
    await fetchCurrentUser();
  };

  const handleConnect = async () => {
    if (openConnectModal) {
      openConnectModal();
    }

    // Ideally you should call a connect method from the SDK here and wait for it to finish
    await fetchCurrentUser();
  };

  return (
    <header className={styles.header}>
      <Container className={styles.headerContainer} width="wide">
        <Link to="https://winweb3.io/">
          <div className={styles.logo}>
            <Logo />
          </div>
        </Link>
        <div className={styles.headerMenu}>
          <HeaderMenu />
        </div>
        <section>
          <PaperEmbeddedWalletProvider
            appName="WinWeb3"
            walletOptions={{
              clientId: process.env.REACT_APP_PAPER_CLIENT_ID || "",
              chain: "Goerli",
              name: "WinWeb3",
              iconUrl: "https://withpaper.com/icons/paper-embedded-wallet-white.png",
              iconBackground: "#432918",
            }}
            modalOptions={{
              theme: darkTheme({
                accentColor: "#A48A7B",
                accentColorForeground: "white",
                borderRadius: "small",
                fontStack: "system",
                overlayBlur: "small",
              }),
            }}
          >
            <div className={styles.connectedWallet}>
              {isConnected ? (
                <Button rounded="full" ref={buttonRef} onClick={handleDisconnect}>
                  Logout
                </Button>
              ) : (
                <Button rounded="full" ref={buttonRef} onClick={handleConnect}>
                  Sign In
                </Button>
              )}
            </div>
          </PaperEmbeddedWalletProvider>
        </section>
      </Container>
    </header>
  );
}

export default Header;