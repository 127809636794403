import React from 'react';
import styles from './Footer.module.scss';
import { Button } from 'semantic-ui-react';




const Footer: React.FC = () => {
  return (
    <div className={styles.footerContainer}>

      <div className={styles.footerMainContent}>

      <article className={styles.footerSubContainerWrapper}>
  <div className={styles.footerSubContainer}>
    <h1 className={styles.footerTitles}>Useful links</h1>
    <ul className={styles.footerLinkContainer}>
      <a href="https://winweb3.io/about/" target="_blank" rel="noopener noreferrer">About us</a>
      <a href="https://winweb3.io/win-club/" target="_blank" rel="noopener noreferrer">WIN’s Membership Club</a>
      <a href="https://winweb3.io/women-stories/" target="_blank" rel="noopener noreferrer">Our Women and Girls</a>
      <a href="https://winweb3.io/faq/" target="_blank" rel="noopener noreferrer">Q&A</a>
    </ul>
  </div>
</article>

<article className={styles.footerSubContainerWrapper}>
  <div className={styles.footerSub}>
    <h1 className={styles.footerTitles}>Gallery</h1>
    <ul className={styles.footerLinkContainer}>
      <a href="https://winweb3.io/the-art/" target="_blank" rel="noopener noreferrer">The Art</a>
      <a href="https://winweb3.io/donating-artists/" target="_blank" rel="noopener noreferrer">Artists</a>
      <a href="https://winweb3.io/shop/" target="_blank" rel="noopener noreferrer">Win Fashion</a>
      <a href="https://winweb3.io/events/" target="_blank" rel="noopener noreferrer">Events</a>
    </ul>
  </div>
</article>

<article className={styles.footerSubContainerWrapper}>
  <div className={styles.footerSub}>
    <h1 className={styles.footerTitles}>Our Support</h1>
    <ul className={styles.footerLinkContainer}>
      <a href="https://winweb3.io/partners/" target="_blank" rel="noopener noreferrer">Partners &amp; Supporters</a>
      <a href="https://winweb3.io/charities/" target="_blank" rel="noopener noreferrer">Charities</a>
      <a href="https://winweb3.io/contact-us/" target="_blank" rel="noopener noreferrer">Contact us</a>
    </ul>
  </div>
</article>

<article className={styles.footerSubContainerWrapper}>
  <div className={styles.footerSub}>
    <h1 className={styles.footerTitles}>Follow us</h1>
    <div className={styles.footer__social_icons}>
      <a  href="https://www.instagram.com/winweb3/" target="_blank" rel="noopener noreferrer" className={styles.footerSocial}>
        <i className="instagramIcon"></i>
        <svg xmlns="http://www.w3.org/2000/svg" height="3.75em" viewBox="0 0 448 512">
          <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
        </svg>
      </a>
      <a  href="mailto:support@Winweb3.io" target="_blank" rel="noopener noreferrer" className={styles.footerSocial}>
        <i className="emailIcon"></i>
        <svg xmlns="http://www.w3.org/2000/svg" height="3.75em" viewBox="0 0 512 512">
          <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
        </svg>
      </a>
    </div>
  </div>
  <ul className={styles.footerLinkContainer}>
    <a href="https://winweb3.io/terms-and-conditions/" target="_blank" rel="noopener noreferrer">Terms</a>
    <a href="https://winweb3.io/privacy-policy-2/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
  </ul>
</article>


      </div>

      <section >
        <Button >
      <div className={styles.donateButton}>Donate</div>
      </Button>
      </section>
<div className={styles.copywrite}>
        <div>winweb3.io © {new Date().getFullYear()}</div>
    </div>
    </div>
  );
};

export default Footer;