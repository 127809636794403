import React, { useEffect, useState } from 'react';
import styles from './HeaderCard.module.scss';
import Container from '../Layout/Container/Container';

const HeaderCard = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 750);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 750);
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getImageVariant = () => {
    if (isMobile) {
      return "https://imagedelivery.net/KUkN6Roy9yZq57ikdpH6wg/531416b7-3010-4bb5-641f-082ed4b32400/Mobile";
    } else if (window.innerWidth >= 1920) {
      return "https://imagedelivery.net/KUkN6Roy9yZq57ikdpH6wg/24afd04d-7c21-4ba3-9048-80a5507e6000/Desktop";
    } else if (window.innerWidth >= 768) {
      return "https://imagedelivery.net/KUkN6Roy9yZq57ikdpH6wg/24afd04d-7c21-4ba3-9048-80a5507e6000/Tablet";
    } else if (window.innerWidth >= 1366) {
      return "https://imagedelivery.net/KUkN6Roy9yZq57ikdpH6wg/24afd04d-7c21-4ba3-9048-80a5507e6000/public";
    } else {
      return "";
    }
  };

  const imgSrc = getImageVariant();

  return (
    <div className={styles.heroGrid}>
      <div className={styles.hero}>
        <div className={styles.herotext}>
          <div className={styles.herotexth1}>Welcome to WIN’s Art Gallery</div>
          <div className={styles.herotextp}>Enjoy the artwork, made by girls, women in need and amazing donors</div>
        </div>
        {imgSrc && <img src={imgSrc} alt="header background" className={styles.HeaderCard__image} />}
      </div>
    </div>
  );
};

export default HeaderCard;

