import React, { Component } from "react";
import { Container, Grid, Menu, Dropdown } from "semantic-ui-react";
import styles from './HeaderMenu.module.scss'



const dropdownMenuStyle = {
  padding: "0px",
  borderRadius: "0",
  boxShadow: "none",
  backgroundColor: "#FFFFFF",
};

const dropdownOptionStyle = {
  fontSize: "16px",
  fontWeight: 600,
  color: "#432918",
  fontFamily: "Merriweather",
};

const dropdownOptionHoverStyle = {
  fontSize: "16px",
  fontWeight: 600,
  color: "#A68D7",
  fontFamily: "Merriweather",
  background: "#F9F4EE",
  objectFit: "contain",
  paddingRight: "10px",
};

const dropdownUnderlineStyle = {
  position: "absolute",
  transition: ".3s",
  transitionTimingFunction: "cubic-bezier(.58,.3,.005,1)",
  height: "3px",
  width: "100%",
  left: 0,
  zIndex: 2,
  backgroundColor: "#cbbba0",
  bottom: "-8px",
};

class HeaderMenu extends Component {
  state = {
    hoverItemIndex: -1,
  };

  handleMouseEnter = (index: number) => {
    this.setState({ hoverItemIndex: index });
  };

  handleMouseLeave = () => {
    this.setState({ hoverItemIndex: -1 });
  };

  render() {
    const { hoverItemIndex } = this.state;

    const dropdownUnderlineStyle: React.CSSProperties = {
        // Dropdown underline styles
      };
      


    return (
      <div className={styles.headerMenu}>
        <Container>
          <Grid columns={1} centered>
            <Grid.Column>
              <Menu secondary fluid widths={8}>
                <Menu.Item>
                  <Dropdown
                    text="Home"
                    className="dropdownItem"
                    style={dropdownOptionStyle}
                    onMouseEnter={() => this.handleMouseEnter(0)}
                    onMouseLeave={this.handleMouseLeave}
                  >
                    <Dropdown.Menu style={dropdownMenuStyle}>
                      <Dropdown.Item
                        as="a"
                        href="https://winweb3.io/"
                        style={
                          hoverItemIndex === 0
                            ? dropdownOptionHoverStyle
                            : dropdownOptionStyle
                        }
                      >
                        Home
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {hoverItemIndex === 0 && <div style={dropdownUnderlineStyle} />}
                </Menu.Item>

                <Menu.Item>
                  <Dropdown
                    text="About"
                    className="dropdownItem"
                    style={dropdownOptionStyle}
                    onMouseEnter={() => this.handleMouseEnter(1)}
                    onMouseLeave={this.handleMouseLeave}
                  >
                    <Dropdown.Menu style={dropdownMenuStyle}>
                      <Dropdown.Item
                        as="a"
                        href="https://winweb3.io/about/"
                        style={
                          hoverItemIndex === 1
                            ? dropdownOptionHoverStyle
                            : dropdownOptionStyle
                        }
                      >
                        About Us
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="a"
                        href="https://winweb3.io/about/#mission"
                        style={
                          hoverItemIndex === 1
                            ? dropdownOptionHoverStyle
                            : dropdownOptionStyle
                        }
                      >
                        Our Mission
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="a"
                        href="https://winweb3.io/about/#team"
                        style={
                          hoverItemIndex === 1
                            ? dropdownOptionHoverStyle
                            : dropdownOptionStyle
                        }
                      >
                        Team
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="a"
                        href="https://winweb3.io/women-stories/"
                        style={
                          hoverItemIndex === 1
                            ? dropdownOptionHoverStyle
                            : dropdownOptionStyle
                        }
                      >
                        Our Women and Girls
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="a"
                        href="https://winweb3.io/faq/"
                        style={
                          hoverItemIndex === 1
                            ? dropdownOptionHoverStyle
                            : dropdownOptionStyle
                        }
                      >
                        FAQ
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {hoverItemIndex === 1 && <div style={dropdownUnderlineStyle} />}
                </Menu.Item>
                <Menu.Item>
  <Dropdown
    text="Collaborations"
    className="dropdownItem"
    style={dropdownOptionStyle}
    onMouseEnter={() => this.handleMouseEnter(2)}
    onMouseLeave={this.handleMouseLeave}
  >
    <Dropdown.Menu style={dropdownMenuStyle}>
      <Dropdown.Item
        as="a"
        href="https://winweb3.io/partners/"
        style={
          hoverItemIndex === 2
            ? dropdownOptionHoverStyle
            : dropdownOptionStyle
        }
      >
        Partners
      </Dropdown.Item>
      <Dropdown.Item
        as="a"
        href="https://winweb3.io/charities/"
        style={
          hoverItemIndex === 2
            ? dropdownOptionHoverStyle
            : dropdownOptionStyle
        }
      >
        Charities we work with
      </Dropdown.Item>
      <Dropdown.Item
        as="a"
        href="https://winweb3.io/partnering-projects/"
        style={
          hoverItemIndex === 2
            ? dropdownOptionHoverStyle
            : dropdownOptionStyle
        }
      >
        Projects we support
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
  {hoverItemIndex === 2 && <div style={dropdownUnderlineStyle} />}
</Menu.Item>
<Menu.Item>
  <Dropdown
    text="Shop"
    className="dropdownItem"
    style={dropdownOptionStyle}
    onMouseEnter={() => this.handleMouseEnter(3)}
    onMouseLeave={this.handleMouseLeave}
  >
    <Dropdown.Menu style={dropdownMenuStyle}>
      <Dropdown.Item
        as="a"
        href="https://winweb3.io/win-shop/"
        style={
          hoverItemIndex === 3
            ? dropdownOptionHoverStyle
            : dropdownOptionStyle
        }
      >
        Shop
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
  {hoverItemIndex === 3 && <div style={dropdownUnderlineStyle} />}
</Menu.Item>

<Menu.Item>
  <Dropdown
    text="Gallery"
    className="dropdownItem"
    style={dropdownOptionStyle}
    onMouseEnter={() => this.handleMouseEnter(4)}
    onMouseLeave={this.handleMouseLeave}
  >
    <Dropdown.Menu style={dropdownMenuStyle}>
      <Dropdown.Item
        as="a"
        href="https://winweb3.io/the-art/"
        style={
          hoverItemIndex === 4
            ? dropdownOptionHoverStyle
            : dropdownOptionStyle
        }
      >
        The Art
      </Dropdown.Item>
      <Dropdown.Item
        as="a"
        href="https://winweb3.io/donating-artists/"
        style={
          hoverItemIndex === 4
            ? dropdownOptionHoverStyle
            : dropdownOptionStyle
        }
      >
        Artists
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
  {hoverItemIndex === 4 && <div style={dropdownUnderlineStyle} />}
</Menu.Item>

<Menu.Item>
  <Dropdown
    text="Events"
    className="dropdownItem"
    style={dropdownOptionStyle}
    onMouseEnter={() => this.handleMouseEnter(5)}
    onMouseLeave={this.handleMouseLeave}
  >
    <Dropdown.Menu style={dropdownMenuStyle}>
      <Dropdown.Item
        as="a"
        href="https://winweb3.io/events/"
        style={
          hoverItemIndex === 5
            ? dropdownOptionHoverStyle
            : dropdownOptionStyle
        }
      >
        Events
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
  {hoverItemIndex === 5 && <div style={dropdownUnderlineStyle} />}
</Menu.Item>

<Menu.Item>
  <Dropdown
    text="Win Club"
    className="dropdownItem"
    style={dropdownOptionStyle}
    onMouseEnter={() => this.handleMouseEnter(6)}
    onMouseLeave={this.handleMouseLeave}
  >
    <Dropdown.Menu style={dropdownMenuStyle}>
      <Dropdown.Item
        as="a"
        href="https://winweb3.io/win-club/"
        style={
          hoverItemIndex === 6
            ? dropdownOptionHoverStyle
            : dropdownOptionStyle
        }
      >
        Win Club
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
  {hoverItemIndex === 6 && <div style={dropdownUnderlineStyle} />}
</Menu.Item>

<Menu.Item>
  <Dropdown
    text="Contact"
    className="dropdownItem"
    style={dropdownOptionStyle}
    onMouseEnter={() => this.handleMouseEnter(7)}
    onMouseLeave={this.handleMouseLeave}
  >
    <Dropdown.Menu style={dropdownMenuStyle}>
      <Dropdown.Item
        as="a"
        href="https://winweb3.io/contact-us/"
        style={
          hoverItemIndex === 7
            ? dropdownOptionHoverStyle
            : dropdownOptionStyle
        }
      >
        Contact Us
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
  {hoverItemIndex === 7 && <div style={dropdownUnderlineStyle} />}
</Menu.Item>
</Menu>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default HeaderMenu;